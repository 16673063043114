import * as Realm from 'realm-web';

const APP_ID = process.env.REACT_APP_MONGODB_APP_ID;

export function getGraphqlLink() {
	return process.env.REACT_APP_GRAPHQL_URL;
}

// Get a valid Realm user access token to authenticate requests
export async function getValidAccessToken() {
	const app = new Realm.App(APP_ID);
	if (!app.currentUser) {
		return undefined
	} else {
		await app.currentUser.refreshCustomData();
	}
	return app.currentUser.accessToken;
}

export async function getAppUsers() {
	const app = new Realm.App(APP_ID);
	let user_list = app.users
	console.log(user_list)
	console.log(app.currentUser)
}

export async function getCurrentUser() {
	try {
		const user = getRealmApp()
		if (user.currentUser) {
			const { currentUser } = user
			if (currentUser.customData.active !== false) {
				await currentUser.refreshCustomData()
				return currentUser
			} else {
				await removeAllUsers()
				throw 'Este usuário foi desativado'
			}
		} else {
			return null
		}
	} catch (error) {
		throw error
	}
}

export function getRealmApp() {
	const app = new Realm.App(APP_ID);
	return app;
}

export async function removeAllUsers() {
	const app = new Realm.App(APP_ID);
	let user_list = app.users;
	for (let index = 0; index < user_list.length; index++) {
		const element = user_list[index];
		app.removeUser(element)
	}
}

export async function logoutUser() {
	const app = new Realm.App(APP_ID);
	if (app.currentUser !== null) app.currentUser.logOut()
	console.log("Logged out user.")
}

export async function loginUser(payload) {
	let user;
	const app = new Realm.App(APP_ID);
	const credentials = Realm.Credentials.function(payload);
	try {
		if (!app.currentUser) {
			user = await app.logIn(credentials);
		} else {
			await app.currentUser.refreshCustomData();
			user = app.currentUser;
		}
		return user
	} catch (err) {
		//return { err, status: undefined }
		throw err
	}
}

export async function loginApiKey() {
	const app = new Realm.App(APP_ID);
	// Create an API Key credential
	const credentials = Realm.Credentials.apiKey(process.env.REACT_APP_MONGODB_LOGIN_API_KEY)
	//const credentials = Realm.Credentials.apiKey("KxqTSuZftzDgw4Omv7uIKxeQSsG73ltGyFvmS48G54wZCZEUzebspl7ncnTErMYt");
	try {
		if (!app.currentUser) {
			// Authenticate the user
			const user = await app.logIn(credentials)
			// `App.currentUser` updates to match the logged in user
			return user
		} else {
			return app.currentUser
		}
	} catch (err) {
		console.error("Failed to log in", err);
	}
}

export async function loginApiKeyRoyalAdviceSite() {
	try {
		const APP_ID = process.env.REACT_APP_MONGODB_APP_ID_ROYAL_ADVICE_SITE
		const API_KEY = process.env.REACT_APP_MONGODB_LOGIN_API_KEY_ROYAL_ADVICE_SITE

		const app = new Realm.App(APP_ID)
		const credentials = Realm.Credentials.apiKey(API_KEY)
		if (!app.currentUser) {
			const user = await app.logIn(credentials)
			return user
		} else {
			return app.currentUser
		}
	} catch (err) {
		console.error("Failed to log in", err);
	}
}

export async function getPrivacyCollection() {
	try {
		const user = await loginApiKeyRoyalAdviceSite()
		const mongodb = user?.mongoClient('mongodb-atlas')
		return mongodb?.db('privacy-terms-db').collection('privacy')
	} catch (error) {
		console.error("Failed to get privacy collection", error)
	}
}

export async function getOnePrivacy(filter) {
	try {
		const privacy = await getPrivacyCollection();
		const selectedPrivacy = await privacy.findOne(filter);
		return selectedPrivacy;
	} catch (error) {
		console.error("Failed to get privacy", error)
	}
}

export async function insertOnePrivacy(data) {
	try {
		const privacy = await getPrivacyCollection()
		return await privacy.insertOne(data)
	} catch (error) {
		console.error("Failed to insert data in privacy collection", error)
	}
}

export async function updateOnePrivacy(query, data) {
	try {
		const privacy = await getPrivacyCollection()
		return await privacy.updateOne(query, data)
	} catch (error) {
		console.error("Failed to update privacy", error)
	}
}

export async function deleteOnePrivacy(query) {
	try {
		const privacy = await getPrivacyCollection()
		return await privacy.deleteOne(query)
	} catch (error) {
		console.error("Failed to delete privacy", error)
	}
}

export async function updateUserCustomData(user, payload) {
	await user.callFunction("custom_update_user_custom_data", payload);
	return true
}

export async function getUserCustomData() {

}