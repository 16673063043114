import { createStore, applyMiddleware } from "redux"
//import { persistStore, persistReducer } from 'redux-persist'
import ReduxAsyncQueue from 'redux-async-queue'
//import storage from 'redux-persist/lib/storage'
//import logger from 'redux-logger'
//import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'

import rootReducers from '../reducers'

const store = createStore(
    rootReducers,
    {},
    //composeWithDevTools(
        applyMiddleware(/* logger, thunk, */ promise, ReduxAsyncQueue),
        //install()
    //)
)

store.subscribe(() => {
    //console.log("Store updated!", store.getState());
})

export { store }