import React, { lazy, Suspense } from "react";
import {
	BrowserRouter as Router,
	Switch
} from "react-router-dom"

import { useAuth } from "../providers/auth.provider"

import Loading from '../screens/Loading/index'
const LoginRoute = lazy(() => import('./loginRoute'));
const MainRoute = lazy(() => import('./mainRoute'));

export default function Routes() {
	const { user, authLoading } = useAuth()

	return (
		<Router>
			<Suspense fallback={<Loading />}>
				{!authLoading ? <Switch>
					{!user
						? <LoginRoute />
						: <MainRoute />
					}
				</Switch> : <Loading />}
			</Suspense>
		</Router>
	)
}