//LIBS
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'

//COMPONENTS
import { store } from '../redux/store/index'
import Routes from "../routes/routes"
import { AuthProvider } from '../providers/auth.provider'
import './index.css'

export default function App() {
    return (
        <AuthProvider>
            <Provider store={store}>
                <Routes />
            </Provider>
        </AuthProvider>
    )
}
