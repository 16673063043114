import React, { useContext, useState, useEffect, useRef } from "react";
import {
    getCurrentUser,
    removeAllUsers, loginUser, updateUserCustomData
} from "../realm/connection";
import { getToken, onMessage, getMessaging } from 'firebase/messaging';
import { toaster, Message } from "rsuite";
import { User } from "realm-web";

export type Department = "Personal" | "Accounting" | "Fiscal" | "Process" | "Administrative"

type CustomData = {
    email: string
    id: string
    master: boolean
    name: string
    picture_upload: any
    user_access: string
    user_id: {
        $oid: string
    }
    _id: string
    active: boolean
    department?: Department
}

type RealmUser = User<Realm.DefaultFunctionsFactory & Realm.BaseFunctionsFactory, CustomData, Realm.DefaultUserProfileData> | null

type SignIn = (payload: {
    email: string
    password: string
}) => Promise<any>

interface ContextResult {
    signIn: SignIn
    signOut: () => Promise<void>
    authLoading: boolean
    setAuthLoading: (v: boolean) => void
    user: RealmUser
}

const AuthContext = React.createContext<ContextResult | null>(null);

const AuthProvider = ({ children }: any) => {
    const [user, setUser] = useState<RealmUser | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [authLoading, setAuthLoading] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                setAuthLoading(true)
                let user: any = await getCurrentUser()
                setUser(user)
                setAuthLoading(false)
            } catch (error) {
                console.log(error)
                setAuthLoading(false)
                let msgError = error === 'Este usuário foi desativado' ? error : 'Ocorreu algum erro ao tentar buscar as informações do usuário'

                toaster.push(
                    <Message type="error" showIcon closable>{msgError}</Message>
                )
            }
        })()
    }, [])

    useEffect(() => {
        setIsAuthenticated(!!user)
    }, [user])

    useEffect(() => {
        if (isAuthenticated) createCustomDataWatch()
    }, [isAuthenticated])

    const createCustomDataWatch = async () => {
        if (user) {
            const mongo = user.mongoClient("mongodb-atlas")
            const customUserData = mongo.db("royaladvicev2").collection("custom_user_data_panel")
            const cursor = customUserData.watch()

            for await (const change of cursor) {
                if (user) {
                    const { operationType } = change
                    if (operationType === 'update') {
                        const { fullDocument } = change
                        if (fullDocument.email === user.customData.email) {
                            const customData = await user.refreshCustomData()

                            if (customData.active) {
                                setUser(prevState => {
                                    const newState: any = { ...prevState, customData }
                                    return newState
                                })
                            } else {
                                setUser(null)
                                removeAllUsers()
                            }
                        }
                    }
                }
            }
        }
    }

    const signIn: SignIn = async (payload) => {
        try {
            const user: any = await loginUser(payload);
            if (user) {
                //UPDATE USER CUSTOM DATA
                const { id } = user.identities[0];
                const payload = {
                    user_find: id,
                    auth_id: user.id
                }

                await updateUserCustomData(user, payload);

                //REFRESH CUSTOM USER DATA
                await user.refreshCustomData();

                //SET USER TO PROVIDER
                if (user.customData.active) {
                    setUser(user);
                } else {
                    await removeAllUsers()
                    throw 'Esse usuário está desativado'
                }
            }
        } catch (err) {
            throw err
        }
    };

    // const signUp = async (email, password) => {

    // };

    const signOut = async () => {
        if (user == null) {
            console.warn("Not logged in, can't log out!");
            return;
        }

        try {
            setAuthLoading(true)
            const messaging = getMessaging()
            const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID })
                .then(res => res)
                .catch(err => console.log('error trying to get notification id: ', err))

            if (currentToken) {
                let notificationIdSaved = localStorage.getItem('notification_id_saved')
                if (notificationIdSaved === 'true') {
                    await user.callFunction('removeSystemUserNotificationId', {
                        userId: user.customData.user_id.$oid,
                        notificationId: currentToken
                    }).catch((err: any) => {
                        console.log("Error on remove notification id: ", err)
                    })
                }
            }

            await removeAllUsers()
            localStorage.removeItem('archive_token')
            localStorage.removeItem('notification_id_saved')
            localStorage.removeItem('dropdownOption')
            setUser(null)
            setAuthLoading(false)
        } catch (err) {
            console.log("error on log out: ", err)
            setAuthLoading(false)
            toaster.push(
                <Message type="error" showIcon closable>Ocorreu algum erro ao tentar deslogar</Message>
            )
        }
    };

    return (
        <AuthContext.Provider
            value={{
                // signUp,
                signIn,
                signOut,
                authLoading,
                setAuthLoading,
                user,
                // projectData, // list of projects the user is a memberOf
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const auth = useContext(AuthContext);
    if (auth == null) {
        throw new Error("useAuth() called outside of a AuthProvider?");
    }
    return auth;
};

export { AuthProvider, useAuth };