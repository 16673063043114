/* import { combineReducers } from 'redux'
import loginReducer from '../reducers/loginReducer'
import projectsReducer from '../reducers/projectsReducer'
import systemUserReducer from '../reducers/systemUserReducer'
import companiesReducer from '../reducers/companiesReducer'
import notificationsReducer from '../reducers/notificationsReducer' */
import { combineReducers } from 'redux'

import rootReducer from './rootReducer'

export default combineReducers({
    rootReducer
})