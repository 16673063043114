import produce from "immer"

const rootReducer = produce((draft = {
  loading: false,
  authenticated: false,
  config: null
}, action) => {
  //console.log(action)
  const { type, payload } = action
  switch (type) {
    case 'SET_LOADING': {
      draft.loading = payload
      return draft
    }
    case 'SET_CONFIG': {
      draft.config = payload
      return draft
    }
    default: {
      return draft
    }
  }
})

export default rootReducer