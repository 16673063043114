import React from 'react'
import {Loader} from 'rsuite'
import styles from './index.module.css'

export default function Loading() {
  return (
    <div className={`${styles.containerbody}`}>
      <Loader center content="loading" />
    </div>
  )
}
